// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const vuetify = async () => {
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    const { default: Vuetify } = await import(/* webpackChunkName: "vuetify" */ 'vuetify/lib');
    Vue.use(Vuetify);

    return new Vuetify({
        theme: { dark: true }
    });
};

export default vuetify;
