import _ from 'lodash';
import { ActionContext, Module } from 'vuex';
import IRootState from './rootState';
import { IDashboard } from '@/repositories';

// state
interface IDashboardsState {
    dashboards: IDashboard[];
}

// vuex module
class DashboardsStore implements Module<IDashboardsState, IRootState> {
    namespaced = true;

    // state
    state: IDashboardsState = {
        dashboards: [] as IDashboard[]
    };

    // getters
    getters = {
        dashboards(s: IDashboardsState) {
            return s.dashboards;
        },

        dashboardById(s: IDashboardsState) {
            return (id: string) => _.find(s.dashboards, (d: IDashboard) => d.id === id);
        }
    };

    // mutations
    mutations = {
        setDashboards(s: IDashboardsState, value: IDashboard[]) {
            s.dashboards = value;
        }
    };

    // actions
    actions = {
        async loadDashboards({ rootState, commit }: ActionContext<IDashboardsState, IRootState>): Promise<void> {
            const dashboards = await rootState.container.apiClient.getAll();
            commit('setDashboards', _.sortBy(dashboards, ['rank', x => x.name.toLocaleLowerCase()]));
        }
    };
}

export { IDashboardsState, DashboardsStore };

export default new DashboardsStore();
