import routes from '@/routes';

export const router = async () => {
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    const { default: VueRouter } = await import(/* webpackChunkName: "vue-router" */ 'vue-router');
    Vue.use(VueRouter);

    return new VueRouter({
        routes
    });
};

export default router;
