import { StoreOptions } from 'vuex';
import stores, { IRootState } from '@/stores';
import Container from '@/container';

const store = async () => {
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    const { default: Vuex } = await import(/* webpackChunkName: "vuex" */ 'vuex');
    Vue.use(Vuex);

    return new Vuex.Store({
        state: { container: new Container() },
        modules: stores,
        strict: true
    } as StoreOptions<IRootState>);
};

export { store };

export default store;
