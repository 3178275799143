import IRootState from './rootState';
import dashboards from './dashboardsStore';
import { ModuleTree } from 'vuex';

const stores = {
    dashboards
} as ModuleTree<IRootState>;

export * from './rootState';
export * from './dashboardsStore';
export { dashboards as dashboardsStore };
export default stores;
