import { DashboardsApiClient } from '@/repositories';

export interface IContainer {
    apiClient: DashboardsApiClient;
}

export default class Container implements IContainer {
    get apiClient() {
        return new DashboardsApiClient();
    }
}
